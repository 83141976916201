import Container from "react-bootstrap/Container";

export function Fallback() {
  return (
    <Container className="my-5">
      <h2 className="mb-3">Wystąpił błąd</h2>
      <button
        className="btn btn-outline-primary me-2"
        onClick={() => window.location.reload()}
      >
        Odśwież stronę
      </button>
      <button
        className="btn btn-outline-primary"
        onClick={() => localStorage.clear()}
      >
        Wyczyść pamięć podręczną
      </button>
    </Container>
  );
}
