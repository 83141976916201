import "./StopPointsMap.css";

import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";

import L from "leaflet";
import ReactDOMServer from "react-dom/server";
import bus from "../assets/bus.svg";
import tram from "../assets/tram.svg";
import { useStopPoints } from "../hooks/useStopPoints";
import { FitBounds } from "./FitBounds";

interface StopPointMarkerProps {
  name: string;
  number: number;
  category: string;
  latitude: number;
  longitude: number;
}

function StopPointMarker({
  name,
  number,
  category,
  latitude,
  longitude,
}: StopPointMarkerProps) {
  const map = useMap();

  const numberText = number.toString().padStart(2, "0");

  const element = (
    <div style={{ position: "relative" }}>
      <img
        src={category === "tram" ? tram : bus}
        alt=""
        style={{ width: 24, height: 30 }}
      />
      <span
        style={{
          fontSize: 11,
          fontWeight: "bold",
          color: "yellow",
          position: "absolute",
          bottom: 1,
          right: 2.25,
          lineHeight: 0.75,
        }}
      >
        {numberText}
      </span>
    </div>
  );

  const icon = L.divIcon({
    html: ReactDOMServer.renderToStaticMarkup(element),
    className: undefined,
    iconSize: [24, 30],
    iconAnchor: [12, 15],
  });

  const eventHandlers = {
    click: () => {
      requestAnimationFrame(() => {
        map.panTo([latitude, longitude], { animate: true, duration: 0.45 });
      });
    },
  };

  return (
    <Marker
      position={[latitude, longitude]}
      icon={icon}
      zIndexOffset={100}
      eventHandlers={eventHandlers}
    >
      <Popup offset={[0, -5]} className="popup">
        <strong>{name}</strong>
        <br />
        Przystanek {category === "tram" ? "tramwajowy" : "autobusowy"}
      </Popup>
    </Marker>
  );
}

interface StopPointsMapProps {
  stopName: string;
}

export function StopPointsMap({ stopName }: StopPointsMapProps) {
  const { stopPoints, error } = useStopPoints(stopName);

  if (!stopPoints.length || error) {
    return null;
  }

  return (
    <MapContainer
      style={{ width: "100%", aspectRatio: "1/1" }}
      center={[0, 0]}
      zoom={10}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
        subdomains="abcd"
      />
      <FitBounds>
        {stopPoints.map((stopPoint) => (
          <StopPointMarker
            name={stopPoint.stop_point_name}
            number={stopPoint.stop_point_num}
            category={stopPoint.category}
            latitude={stopPoint.stop_point_lat}
            longitude={stopPoint.stop_point_lon}
            key={`${stopPoint.stop_point_name}_${stopPoint.category}_${stopPoint.stop_point_lat}`}
          />
        ))}
      </FitBounds>
    </MapContainer>
  );
}
