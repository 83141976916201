import { formatTime, isTripHeadsignInternal } from "../utils";

import { Arrival } from "./Arrival";
import { KmkId } from "./KmkId";
import { Link } from "react-router-dom";
import { LatencyIcon } from "./LatencyIcon";
import { RouteShortNameBox } from "./RouteShortNameBox";
import { RouteShortNameBoxPlaceholder } from "./RouteShortNameBoxPlaceholder";
import Spinner from "react-bootstrap/Spinner";
import { StopLink } from "./StopLink";
import Table from "react-bootstrap/Table";
import { Trip } from "../hooks/useBlock";

interface BlockTripsProps {
  category: string;
  blockId: number;
  trips: Trip[];
  loading: boolean;
}

export function BlockTrips({
  category,
  blockId,
  trips,
  loading,
}: BlockTripsProps) {
  return (
    <Table size="sm" striped bordered hover className="table-td-align-middle">
      <thead>
        <tr>
          <th style={{ width: "4%" }} className="text-center">
            Nr
          </th>
          <th style={{ width: "9%" }} className="text-center">
            Odjazd
          </th>
          <th style={{ width: "9%" }} className="text-center">
            Przyjazd
          </th>
          <th className="text-end pe-2">Przystanek początkowy</th>
          <th style={{ width: "7%" }} className="text-center">
            Linia
          </th>
          <th className="ps-2">Kierunek</th>
          <th style={{ width: "10%" }} className="text-center">
            Pojazd
          </th>
          <th style={{ width: "13%" }}></th>
        </tr>
      </thead>
      <tbody>
        {trips.map((trip) => (
          <tr
            key={trip.trip_num}
            className={trip.kmk_id ? "bg-warning bg-opacity-25" : undefined}
          >
            <td className="bold text-center">{trip.trip_num}</td>
            <td className="text-center">
              {trip.departure_time && formatTime(trip.departure_time)}
            </td>
            <td className="text-center">
              {trip.arrival_time && (
                <>
                  {formatTime(trip.arrival_time)}
                  <Arrival />
                </>
              )}
            </td>
            <td className="text-end pe-2">
              {trip.stop_name && (
                <StopLink stopName={trip.stop_name} expandDepotName removeNz />
              )}
            </td>
            <td className="text-center">
              {!trip.hidden && trip.route_short_name ? (
                <RouteShortNameBox routeShortName={trip.route_short_name} />
              ) : (
                <RouteShortNameBoxPlaceholder />
              )}
            </td>
            <td className="ps-2">
              <StopLink
                stopName={trip.trip_headsign}
                expandDepotName
                removeNz
                bold
              />
            </td>
            <td className="text-center">
              {trip.kmk_id !== null && <KmkId kmk_id={trip.kmk_id} />}
              {trip.timestamp !== null && (
                <LatencyIcon timestamp={trip.timestamp} />
              )}
            </td>
            <td className="text-center">
              {!isTripHeadsignInternal(trip.trip_headsign) && (
                <Link
                  to={`/blocks/${category}/${blockId}/trips/${trip.trip_num}`}
                >
                  Rozkład kursu
                </Link>
              )}
            </td>
          </tr>
        ))}
        {loading && (
          <tr>
            <td colSpan={8} className="py-3 text-center">
              <Spinner animation="border" variant="primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </td>
          </tr>
        )}
        {!loading && trips.length === 0 && (
          <tr>
            <td colSpan={8} className="py-3 text-center text-secondary">
              Brak kursów.
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
}
