import { useEffect, useState } from "react";

import { BACKEND_BASE_URL } from "../config";
import { Category } from "./useBlock"; // TODO: move to common types
import { useFetch } from "usehooks-ts";
import { useCacheBuster } from "./useCacheBuster";

export interface StopTime {
  stop_sequence: number;
  stop_name: string;
  stop_point_num: number;
  departure_time: string;
  time: number | null;
  delay: number | null;
  old: boolean;
}

interface StopTimeWithTripNum extends StopTime {
  trip_num: number;
}

interface Trip {
  trip_num: number;
  route_short_name: string;
  trip_headsign: string;
}

function useBlockStopTimes(
  category: Category,
  blockId: number,
  interval: number
) {
  const { cacheBuster } = useCacheBuster(interval);

  const { data, error } = useFetch<{
    stop_times: StopTimeWithTripNum[];
    trips: Trip[];
  }>(
    `${BACKEND_BASE_URL}/api/blocks/${category}/${blockId}/stop_times?t=${cacheBuster}`
  );

  const [stopTimes, setStopTimes] = useState<StopTimeWithTripNum[]>([]);
  const [trips, setTrips] = useState<Trip[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (data) {
      setStopTimes(data.stop_times);
      setTrips(data.trips);
      setLoading(false);
    }
  }, [data]);

  useEffect(() => {
    // reset state when category or blockId changes
    setStopTimes([]);
    setTrips([]);
    setLoading(true);
  }, [category, blockId]);

  return { stopTimes, trips, loading, error };
}

export function useTrip(
  category: Category,
  blockId: number,
  tripNum: number,
  interval: number = 10_000
) {
  const { stopTimes, trips, loading, error } = useBlockStopTimes(
    category,
    blockId,
    interval
  );

  const trip = trips.find((trip) => trip.trip_num === tripNum);

  const routeShortName = trip?.route_short_name;
  const tripHeadsign = trip?.trip_headsign;

  const tripNums = trips.map((trip) => trip.trip_num);
  const nextTripNums = tripNums.filter((num) => num > tripNum);
  const prevTripNums = tripNums.filter((num) => num < tripNum);
  const nextTripNum = nextTripNums.length ? Math.min(...nextTripNums) : null;
  const prevTripNum = prevTripNums.length ? Math.max(...prevTripNums) : null;

  const tripStopTimes = stopTimes.filter(
    (stopTime) => stopTime.trip_num === tripNum
  );

  return {
    routeShortName,
    tripHeadsign,
    stopTimes: tripStopTimes,
    prevTripNum,
    nextTripNum,
    loading,
    error,
  };
}
