import { Arrival } from "./Arrival";
import { Countdown } from "./Countdown";
import { Delay } from "./Delay";
import { StopLink } from "./StopLink";
import { StopTime } from "../hooks/useTrip";
import { Strikethrough } from "./Strikethrough";
import Table from "react-bootstrap/Table";
import { formatTime } from "../utils";

interface TripStopTimesProps {
  stopTimes: StopTime[];
  currentStopSequence?: number | null;
}

export function TripStopTimes({
  stopTimes,
  currentStopSequence = null,
}: TripStopTimesProps) {
  return (
    <Table
      size="sm"
      bordered
      hover
      className="table-td-align-middle table-layout-fixed text-nowrap"
    >
      <thead>
        <tr>
          <th style={{ width: "20%" }} className="text-center">
            Odjazd
          </th>
          <th>Przystanek</th>
          <th style={{ width: "20%" }} className="text-center">
            Czas
          </th>
        </tr>
      </thead>
      <tbody>
        {stopTimes
          .filter(
            (stopTime) =>
              currentStopSequence === null ||
              stopTime.stop_sequence > currentStopSequence - 5
          )
          .map((stopTime, i) => {
            return (
              <tr
                key={stopTime.stop_sequence}
                className={
                  stopTime.old ? "bg-dark bg-opacity-10 opacity-75" : undefined
                }
              >
                <td className="text-center">
                  <Strikethrough enabled={stopTime.old}>
                    {formatTime(stopTime.departure_time)}
                  </Strikethrough>
                  <Delay delay={stopTime.delay} />
                  {i === stopTimes.length - 1 && <Arrival />}
                </td>
                <td className="text-truncate">
                  <Strikethrough enabled={stopTime.old}>
                    {stopTime.stop_sequence}.{" "}
                    <StopLink
                      stopName={stopTime.stop_name}
                      removeNz={
                        stopTime.stop_sequence === 1 ||
                        stopTime.stop_sequence === stopTimes.length
                      }
                    />{" "}
                    <small>
                      {stopTime.stop_point_num.toString().padStart(2, "0")}
                    </small>
                  </Strikethrough>
                </td>
                <td className="text-center">
                  {stopTime.time !== null && (
                    <Countdown timestamp={stopTime.time} />
                  )}
                </td>
              </tr>
            );
          })}
        {stopTimes.length === 0 && (
          <tr>
            <td colSpan={3} className="py-3 text-center text-secondary">
              Brak odjazdów.
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
}
