import { Route, Routes, useLocation } from "react-router-dom";

import { About } from "./pages/About";
import { ActiveVehicles } from "./pages/ActiveVehicles";
import { Block } from "./pages/Block";
import { Blocks } from "./pages/Blocks";
import { Departures } from "./pages/Departures";
import { HiddenTrips } from "./pages/HiddenTrips";
import { Home } from "./pages/Home";
import { NotFound } from "./pages/NotFound";
import { Map } from "./pages/Map";
import { Map2 } from "./pages/Map2";
import { Navigation } from "./components/Navigation";
import { Route as RoutePage } from "./pages/Route";
import { Routes as RoutesPage } from "./pages/Routes";
import { Status } from "./pages/Status";
import { Stop } from "./pages/Stop";
import { Stops } from "./pages/Stops";
import { Trip } from "./pages/Trip";
import { Vehicle } from "./pages/Vehicle";
import { VehicleHistory } from "./pages/VehicleHistory";
import { Vehicles } from "./pages/Vehicles";
import { VehiclesByRoute } from "./pages/VehiclesByRoute";
import { useGoogleAnalytics } from "./hooks/useGoogleAnalytics";
import { withSentryReactRouterV6Routing } from "@sentry/react";

const SentryRoutes = withSentryReactRouterV6Routing(Routes);

export function App() {
  useGoogleAnalytics();

  const location = useLocation();

  return (
    <>
      {location.pathname !== "/map2" && <Navigation />}
      <SentryRoutes>
        <Route path="" element={<Home />} />
        <Route path="map" element={<Map />} />
        <Route path="map/:kmkId" element={<Map />} />
        <Route path="map2" element={<Map2 />} />
        <Route path="routes" element={<RoutesPage />} />
        <Route path="routes/vehicles" element={<VehiclesByRoute />} />
        <Route path="routes/:routeShortName" element={<RoutePage />} />
        <Route
          path="routes/:routeShortName/directions/:directionId"
          element={<Departures />}
        />
        <Route
          path="routes/:routeShortName/directions/:directionId/stops/:stopPointName/departures"
          element={<Departures />}
        />
        <Route path="blocks" element={<Blocks />} />
        <Route path="blocks/:category/:blockId" element={<Block />} />
        <Route
          path="blocks/:category/:blockId/trips/:tripNum"
          element={<Trip />}
        />
        <Route path="trips/hidden" element={<HiddenTrips />} />
        <Route path="stops" element={<Stops />} />
        <Route path="stops/:stopName/:mode" element={<Stop />} />
        <Route path="vehicles" element={<Vehicles />} />
        <Route path="vehicles/active" element={<ActiveVehicles />} />
        <Route path="vehicles/:kmk_id" element={<Vehicle />} />
        <Route path="vehicles/:kmk_id/history" element={<VehicleHistory />} />
        <Route path="status" element={<Status />} />
        <Route path="about" element={<About />} />
        <Route path="*" element={<NotFound />} />
      </SentryRoutes>
    </>
  );
}
