import "./Map.css";

import {
  Circle,
  MapContainer,
  Marker,
  Polyline,
  Popup,
  TileLayer,
  useMap,
  useMapEvents,
} from "react-leaflet";
import { Link, useNavigate, useParams } from "react-router-dom";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Source, Vehicle, useActiveVehicles } from "../hooks/useActiveVehicles";
import {
  compareRouteShortName,
  getFullSourceName,
  isTripHeadsignInternal,
} from "../utils";
import { useLocalStorage, useReadLocalStorage } from "usehooks-ts";

import Button from "react-bootstrap/Button";
import { Category } from "../hooks/useVehicle";
import { Counter } from "../components/Counter";
import { Error } from "../components/Error";
import FormSelect from "react-bootstrap/FormSelect";
import { KmkId } from "../components/KmkId";
import L from "leaflet";
import PageTitle from "../components/PageTitle";
import ReactDOMServer from "react-dom/server";
import Spinner from "react-bootstrap/Spinner";
import { StopLink } from "../components/StopLink";
import { usePath } from "../hooks/usePath";
import { Stop, useStops } from "../hooks/useStops";

import bus from "../assets/bus.svg";
import Modal from "react-bootstrap/Modal";
import { RouteShortNameBox } from "../components/RouteShortNameBox";
import { useFilteredRouteShortNames } from "../hooks/useFilteredRouteShortNames";
import { useRoutes } from "../hooks/useRoutes";

const DEFAULTS = {
  latitude: 50.06,
  longitude: 19.94,
  zoom: 13,
};

interface VehicleWithLatLng extends Vehicle {
  latitude: number;
  longitude: number;
}

interface VehicleMarkerProps {
  vehicle: VehicleWithLatLng;
  onPress: (vehicle: VehicleWithLatLng) => void;
  isSelected: boolean;
}

function VehicleMarker({ vehicle, onPress, isSelected }: VehicleMarkerProps) {
  const map = useMap();

  const navigate = useNavigate();

  const ref = useRef<L.Marker>(null);

  useEffect(() => {
    if (isSelected && ref.current !== null && !ref.current?.isPopupOpen()) {
      map.panTo([vehicle.latitude, vehicle.longitude], { animate: false });
      setTimeout(() => {
        ref.current?.openPopup();
      }, 0);
    }
  }, [map, ref, isSelected, vehicle]);

  const {
    latitude,
    longitude,
    bearing,
    route_short_name: routeShortName,
    trip_headsign: tripHeadsign,
    full_kmk_id: fullKmkId,
    shift,
    category,
    timestamp,
    source,
  } = vehicle;

  const isInternal =
    tripHeadsign !== null && isTripHeadsignInternal(tripHeadsign);

  const isObsolete =
    timestamp !== null && Date.now() - timestamp * 1000 > 7 * 60 * 1000; // 7 min

  const isOutdated =
    timestamp !== null && Date.now() - timestamp * 1000 > 2 * 60 * 60 * 1000; // 2 h

  const color = isOutdated
    ? "rgba(0,0,0,0.2)"
    : isObsolete || isInternal
    ? category === "tram"
      ? "rgba(255,0,0,0.5)"
      : "rgba(0,0,255,0.5)"
    : category === "tram"
    ? "red"
    : "blue";

  const fontSize = category === "tram" ? 14 : 13;

  const text =
    tripHeadsign === "PH" && routeShortName !== "4" && routeShortName !== "22"
      ? "ZNH"
      : tripHeadsign === "PT"
      ? "ZP"
      : routeShortName ?? "?";

  const element = (
    <svg width={40} height={40}>
      {category === "tram" ? (
        <rect
          x={8}
          y={8}
          width={24}
          height={24}
          stroke={color}
          strokeWidth={1}
          strokeOpacity={0.6}
          fill={color}
          fillOpacity={0.6}
          transform={`rotate(${bearing ?? 0} 20 20)`}
          style={{ zIndex: 100 }}
        />
      ) : (
        <circle
          cx={20}
          cy={20}
          r={13}
          stroke={color}
          strokeWidth={1}
          strokeOpacity={0.6}
          fill={color}
          fillOpacity={0.6}
          style={{ zIndex: 100 }}
        />
      )}
      {bearing !== null && (
        <polygon
          points="20,0 26,6 14,6"
          fill={color}
          fillOpacity={0.5}
          transform={`rotate(${bearing} 20 20)`}
          style={{ zIndex: 200 }}
        />
      )}
      <text
        x={20}
        y={20}
        fill="white"
        fontSize={fontSize}
        fontWeight={600}
        textAnchor="middle"
        dominantBaseline="central"
        style={{ zIndex: 300 }}
      >
        {text}
      </text>
    </svg>
  );

  const icon = L.divIcon({
    html: ReactDOMServer.renderToStaticMarkup(element),
    className: undefined,
    iconSize: [40, 40],
    iconAnchor: [20, 20],
  });

  const eventHandlers = {
    click: () => onPress(vehicle),
    popupclose: () => {
      if (ref.current) {
        navigate("/map", { replace: true });
      }
    },
  };

  return (
    <Marker
      position={[latitude, longitude]}
      icon={icon}
      zIndexOffset={isOutdated ? 200 : isObsolete ? 300 : 400}
      eventHandlers={eventHandlers}
      ref={ref}
    >
      <Popup offset={[0, -5]} className="popup">
        {routeShortName !== null && (
          <>
            Linia:{" "}
            <strong>
              <Link to={`/routes/${routeShortName}`} className="hidden-link">
                {routeShortName}
              </Link>
            </strong>
            <br />
          </>
        )}
        {tripHeadsign !== null && (
          <>
            Kierunek:{" "}
            <StopLink stopName={tripHeadsign} bold expandDepotName removeNz />
            <br />
          </>
        )}
        {fullKmkId !== null && (
          <>
            Numer taborowy:{" "}
            <strong>
              <KmkId kmk_id={fullKmkId} />
            </strong>
            <br />
          </>
        )}
        {shift !== null && shift !== routeShortName && (
          <>
            Brygada: <strong>{shift}</strong>
            <br />
          </>
        )}
        <small>
          {timestamp !== null && (
            <>
              <Counter timestamp={timestamp} /> |{" "}
            </>
          )}
          {getFullSourceName(source)}
        </small>
      </Popup>
    </Marker>
  );
}

const MemoVehicleMarker = React.memo(VehicleMarker);

interface StopPointMarkerProps {
  stop: Stop;
}

const stopIcon = new L.Icon({
  iconUrl: bus,
  iconSize: [20, 25],
});

function StopMarker({ stop }: StopPointMarkerProps) {
  return (
    <Marker
      position={[stop.stop_lat, stop.stop_lon]}
      icon={stopIcon}
      zIndexOffset={250}
      alt=""
    >
      <Popup offset={[0, -5]} className="popup">
        <StopLink stopName={stop.stop_name} bold />
      </Popup>
    </Marker>
  );
}

const MemoStopMarker = React.memo(StopMarker);

interface PathPolylineProps {
  category: Category;
  routeShortName: string;
  tripHeadsign: string;
}

function PathPolyline({
  category,
  routeShortName,
  tripHeadsign,
}: PathPolylineProps) {
  const { path, loading, error } = usePath(
    category,
    routeShortName,
    tripHeadsign
  );

  const color = category === "tram" ? "red" : "blue";

  const positions = useMemo(() => {
    return path.map(({ latitude: lat, longitude: lng }) => ({ lat, lng }));
  }, [path]);

  if (loading || error) {
    return null;
  }

  return (
    <Polyline
      positions={positions}
      color={color}
      key={color} // force rerender
      opacity={0.5}
      weight={5}
    />
  );
}

interface Location {
  latitude: number;
  longitude: number;
  accuracy: number;
}

function UserLocation() {
  const map = useMap();

  const [location, setLocation] = useState<Location>();

  const handlePress = () => {
    navigator.geolocation.getCurrentPosition(
      (e) => {
        const { latitude, longitude, accuracy } = e.coords;
        setLocation({ latitude, longitude, accuracy });
        map.flyTo([latitude, longitude], 16);
      },
      (e) => {
        alert(e.message);
      }
    );
  };

  return (
    <>
      <Button
        size="sm"
        style={{ position: "absolute", bottom: 10, left: 10, zIndex: 999 }}
        onClick={handlePress}
      >
        GPS
      </Button>
      {location !== undefined && (
        <Circle
          center={[location.latitude, location.longitude]}
          radius={location.accuracy}
        />
      )}
    </>
  );
}

const STOP_MARKERS_MIN_ZOOM = 15;

function StopMarkers() {
  const map = useMap();

  const [show, setShow] = useState(map.getZoom() >= STOP_MARKERS_MIN_ZOOM);

  useMapEvents({
    zoom() {
      setShow(map.getZoom() >= STOP_MARKERS_MIN_ZOOM);
    },
  });

  const { stops } = useStops();

  const markers = useMemo(() => {
    return stops.map((stop) => (
      <MemoStopMarker key={stop.stop_name} stop={stop} />
    ));
  }, [stops]);

  return show ? <>{markers}</> : null;
}

function MapContents() {
  const source = (useReadLocalStorage("map_source") ?? "ttss") as Source;

  const { vehicles, loading, error } = useActiveVehicles(source, 10_000);

  const { hasFilter, shouldShowRoute } = useFilteredRouteShortNames();

  const filteredVehicles = useMemo(() => {
    let result = vehicles.filter(
      (v) => v.latitude !== null && v.longitude !== null
    ) as VehicleWithLatLng[];
    if (hasFilter) {
      result = result.filter((v) => shouldShowRoute(v.route_short_name ?? ""));
    }
    return result;
  }, [hasFilter, shouldShowRoute, vehicles]);

  const { kmkId } = useParams();

  const map = useMap();

  const navigate = useNavigate();

  const selectedVehicle = useMemo(() => {
    return vehicles.find((v) => v.kmk_id === kmkId);
  }, [vehicles, kmkId]);

  const [center, setCenter] = useState<[number, number]>();

  useEffect(() => {
    if (center !== undefined) {
      map.panTo(center, { animate: true, duration: 0.45 });
    }
  }, [map, center]);

  const handlePress = useCallback(
    (vehicle: Vehicle) => {
      if (vehicle.latitude !== null && vehicle.longitude !== null) {
        setCenter([vehicle.latitude, vehicle.longitude]);
      }
      if (vehicle.kmk_id !== null) {
        navigate(`/map/${vehicle.kmk_id}`, { replace: true });
      }
    },
    [navigate]
  );

  return (
    <>
      {error ? (
        <div className="overlay">
          <div style={{ pointerEvents: "auto" }}>
            <Error error={error} />
          </div>
        </div>
      ) : loading ? (
        <div className="overlay">
          <Spinner animation="border" variant="primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : null}
      <StopMarkers />
      {filteredVehicles.map((vehicle) => (
        <MemoVehicleMarker
          key={vehicle.key}
          vehicle={vehicle}
          onPress={handlePress}
          isSelected={vehicle.kmk_id === kmkId}
        />
      ))}
      {selectedVehicle &&
        selectedVehicle.route_short_name !== null &&
        selectedVehicle.trip_headsign !== null && (
          <PathPolyline
            category={selectedVehicle.category}
            routeShortName={selectedVehicle.route_short_name}
            tripHeadsign={selectedVehicle.trip_headsign}
          />
        )}
    </>
  );
}

function RouteFilter() {
  const { groups } = useRoutes();

  const routeShortNames = useMemo(() => {
    const result = [];
    for (const group of groups) {
      result.push(...group.route_short_names);
    }
    result.sort(compareRouteShortName);
    return result;
  }, [groups]);

  const [show, setShow] = useState(false);

  const {
    hasFilter,
    filteredRouteShortNames,
    shouldShowRoute,
    toggleFilter,
    clearFilter,
  } = useFilteredRouteShortNames();

  const handleOpen = useCallback(() => {
    setShow(true);
  }, []);

  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  return (
    <>
      <Button
        size="sm"
        variant="secondary"
        style={{
          position: "absolute",
          bottom: 10,
          left: 65,
          zIndex: 999,
        }}
        onClick={handleOpen}
      >
        {hasFilter ? `Linie: ${filteredRouteShortNames.join(", ")}` : "Linie"}
      </Button>
      <Modal size="lg" centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Filtruj linie</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ margin: -3 }}>
            {routeShortNames.map((routeShortName) => (
              <div
                key={routeShortName}
                style={{
                  display: "inline-block",
                  padding: 3,
                  opacity: shouldShowRoute(routeShortName) ? 1 : 0.2,
                  cursor: "pointer",
                }}
                onMouseDown={() => toggleFilter(routeShortName)}
              >
                <RouteShortNameBox
                  routeShortName={routeShortName}
                  large
                  link={false}
                />
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {hasFilter && (
            <Button
              size="sm"
              variant="outline-danger"
              onClick={clearFilter}
              disabled={!hasFilter}
            >
              Wyczyść filtr
            </Button>
          )}
          <Button size="sm" variant="outline-secondary" onClick={handleClose}>
            Zamknij
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const SOURCE_NAMES = ["kokon", "gtfs", "ttss"];

function SourceSelect() {
  const [source, setSource] = useLocalStorage("map_source", "kokon");

  const map = useMap();

  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSource(e.target.value);
    navigate("/map", { replace: true });
  };

  const handleFocus = () => {
    // workaround to fix map dragging after clicking selectbox
    map.dragging.disable();
    map.dragging.enable();
  };

  return (
    <FormSelect
      size="sm"
      style={{
        position: "absolute",
        bottom: 50,
        left: 10,
        zIndex: 999,
        width: 88,
      }}
      value={source}
      onChange={handleChange}
      onFocus={handleFocus}
    >
      {SOURCE_NAMES.map((source) => (
        <option value={source} key={source}>
          {getFullSourceName(source)}
        </option>
      ))}
    </FormSelect>
  );
}

export function Map() {
  return (
    <>
      <PageTitle title="Mapa" />
      <MapContainer
        center={[DEFAULTS.latitude, DEFAULTS.longitude]}
        zoom={DEFAULTS.zoom}
        zoomControl={false}
        style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0 }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
          url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
          subdomains="abcd"
        />
        <UserLocation />
        <RouteFilter />
        <SourceSelect />
        <MapContents />
      </MapContainer>
    </>
  );
}
